import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material';
import { useState } from 'react';

const items = [{
    title: "Які саме тренування будуть на курсі?",
    value: "На вас чекають різноманітні та повноцінні тренування, а саме: функціональні тренування, табата (високоінтенсивні інтервальні тренування), баре та розтяжка"
}, {
    title: "Чи потрібен мені додатковий інвентар для тренувань?",
    value: "Всі тренування виконуються без додаткового інвентарю. Проте, якщо ви маєте кільцеву резину, обтяжувачі чи гантелі, то за бажанням ви можете їх використовувати на тренуваннях. Єдине, що нам знадобиться - це стілець для тренувань з баре, але я думаю, що його знайти не буде проблемою"
}, {
    title: "Яка тривалість тренувань та у якому форматі вони записані?",
    value: "Середня тривалість тренувань 35-40 хвилин, вони записані від початку до кінця разом зі мною та моєю підтримкою. Також ви будете мати можливість виконувати їх у зручний для вас чаc"
}, {
    title: "Як довго будуть зберігатися відео- та текстові матеріали курсу?",
    value: "Місяць після закінчення курсу, тобто до 14.11.2024 року"
}, {
    title: "Чи підійдуть мені тренування, якщо я давно не тренувалась?",
    value: "Тренування розраховані для середнього рівня важкості, але у будь-якому випадку навантаження можна корегувати, про це я буду зазначати на початку кожного тренування"
}, {
    title: "Якщо я не хочу харчуватися по розписаному раціону?",
    value: "Раціон, написаний до курсу - це ніби шпаргалка, яка допоможе тобі отримати готовий приклад збалансованого раціону на день. Проте не обовʼязково йти чітко по ньому. Якщо ти не хочу готувати страви, які прописані на вівторок другого тижня - ти можеш приготувати меню, яке розписане на середу першого тижня (як приклад)"
}];

function ExpandIcon() {
    return <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line y1="16.5" x2="35" y2="16.5" stroke="#3E2C22"/>
        <line x1="17.5" y1="35" x2="17.5" stroke="#3E2C22"/>
    </svg>
}

function CollapseIcon() {
    return <svg width="35" height="1" viewBox="0 0 35 1" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line y1="0.5" x2="35" y2="0.5" stroke="#3E2C22"/>
    </svg>
}

const CustomAccordion = styled(Accordion)({
    boxShadow: 'none',
    ':before': {
        display: 'none',
    }
});

function AccordionItem({ item }: any) {
    const [expanded, setExpanded] = useState(false);

    const handleChange = () => {
        setExpanded((prev) => !prev);
    };

    return <CustomAccordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary
            expandIcon={expanded ? <CollapseIcon /> : <ExpandIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{
                borderTop: '0.5px solid #3E2C22',
            }}
        >
            <div className="text-regular uppercase font-semibold pr-4">{item.title}</div>
        </AccordionSummary>
        <AccordionDetails>
            <div className="text-regular">{item.value}</div>
        </AccordionDetails>
    </CustomAccordion>
}

export function FAQ() {
    return (
        <div>
            <div className="uppercase mb-10">[FAQ]</div>
            <div className="-mx-[14px]">
                {items.map((item, i) => {
                    return (
                        <AccordionItem key={i} item={item}/>
                    )
                })}
            </div>
        </div>
    )
}
