import "./StartPathBanner.css";

const firstLine = "Почни свій шлях вже"
const secondLine = "25 вересня"

export function StartPathBanner() {
    return (
        <div className="StartPathBanner">
            <img className="w-full" alt="Почни свій шлях вже 25 вересня" src="./for-whom-banner.png"/>
            <div className="StartPathBanner--text relative h-full">
                {firstLine.split(" ").map((word, i) => {
                    return <div key={i}>{word}</div>
                })}
                <div className="StartPathBanner--text__white absolute bottom-[10%] right-0">
                    {secondLine.split(" ").map((word, i) => {
                        return <div key={i}>{word}</div>
                    })}
                </div>
            </div>
        </div>
    )
}
