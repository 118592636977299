import "./Banner.css";
import { Button, styled } from '@mui/material';

const CustomButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'uppercase',
    borderRadius: '100%',
    width: 123,
    height: 123,
    padding: 0,
    justifyContent: "flex-start",
    textAlign: "left",
    border: '1px solid #3E2C22',
    fontFamily: [
        'Cormorant Unicase',
    ].join(','),
    '&:hover': {
        backgroundColor: '#3E2C22',
        borderColor: '#3E2C22',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#3E2C22',
        borderColor: '#3E2C22',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem #3E2C22',
    },
});

function ReserveButton() {
    return (
        <CustomButton variant="contained" onClick={() => {
            window.location.href = "https://secure.wayforpay.com/payment/fitnessdays";
        }}>
            <div className="BookButton--content ml-2 uppercase">
                забронювати місце
            </div>
        </CustomButton>
    )
}

export function Banner() {
    return (
        <div className="mt-10">
            <div className="flex flex-col relative">
                <div className="Banner-title z-10">
                    <div className="flex flex-col gap-10 max-w-[95vw] overflow-hidden">
                        <div>Fitness</div>
                        <div>Days</div>
                        <div>[4.0]</div>
                    </div>
                    <div className="mt-[60px]"><ReserveButton /></div>
                </div>
                <img alt="Main logo" className="BannerImage w-full" src="./mobile-banner.png"/>
            </div>
        </div>
    )
}
